.usado-detail {

  #send-consulta-usado {
    .form-control {
      margin-bottom: 5px;
    }

  }

  .data {
    h2, h3, h4 {
      font-weight: 800;
      font-family: $FontWeb
    }

    .titulo {
      color: $color_primary;
    }

    .old {
      font-size: 2.6rem;
    }

    ul {
      display: flex;
      padding-bottom: 5px;

      li {
        padding-right: 10px;
        text-align: center;
      }
    }

    .comentario {
      white-space: break-spaces;
      font-weight: 400;
      padding: 0;
      line-height: 1.2;
      font-size: 1.4rem !important;
      font-family: $FontWeb !important;
    }

    .default-text {
      p, samp {
        font-family: $FontWeb !important;
        font-weight: 400 !important;
        padding: 0;
        line-height: 1.2 !important;
        font-size: 1.4rem !important;
        margin-bottom: 15px;
      }
    }
  }

  .btn {
    font-family: $FontWeb;
    border: 1px solid $color_primary;
    background: transparent !important;
    color: $color_primary;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.3rem;

    &:hover {
      background: $color_primary !important;
      color: white !important;
    }
  }

  .accesorios {
    position: fixed;
    left: 50%;
    height: auto;
    max-height: 80%;
    overflow-x: auto;
    top: -550%;
    width: 80vw;
    transform: translate(-50%, 0);
    background: white;
    padding: 20px;
    z-index: 999;
    box-shadow: 0 0 5px grey;
    transition: all .6s ease-in-out;


    &.active {
      top: 50%;
      transform: translate(-50%, -50%);
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        font-weight: 700;
        font-family: $FontWeb;
        border-bottom: 1px solid lightblue;
        flex-basis: 48%;
        text-align: center;
        padding: 5px;
        font-size: 1.2rem;
      }
    }

    // Visor accesorios mobile
    @include mobile {
      position: relative;
      left: unset;
      top: unset;
      transform: unset;
      width: unset;
      z-index: unset;

      &.active {
        top: 0;
      }

      ul {
        li {
          flex-basis: 100%;
        }
      }
    }
  }

  .back-accesorios {
    background: rgba(black, .5);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 888;
    display: none;
  }

  .botones {
    margin-top: 5px;
    margin-bottom: 10px;
    display: block;

    > div {
      padding-right: 5px;
      padding-left: 0;
    }
  }
}