section[data-section="elegir-medio"] {
  .item-tipo-pago {
    text-align: center;

    figure {

      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 15px;

      img {
        margin: 0 auto;
        display: block;
        max-width: 280px;
        line-height: 100px;
        vertical-align: middle;
      }
    }

    h3 {
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 10px;
    }
    p{
      color:grey;
      font-weight: 400;
      font-size: 1.4rem;
    }
  }
}