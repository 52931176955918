section[data-section="home-previa"] {
  h1, h2, h3 {
    margin: 0;
  }

  .slider {

  }

  .text {
    display: block;
    padding: 50px 0;
    //border: 1px solid red;
    text-align: center;

    .t {
      padding-top: 10px;
      display: block;

      p {
        font-size: 2rem;
        color: black;
        padding: 0;
        line-height: .8;
        margin: 0 0 2px 0;
      }
    }

    h2 {
      font-weight: 700;
      color:orange;
      font-size: 3.8rem;
      margin-bottom: 5px;
    }
  }

  .form-home-previa {
    padding: 20px;
    width: 90%;
    margin: 25px auto 0 auto;
    display: block;
    background: rgba(grey,.2);

    ul {
      display: flex;

      li {
        flex: 1 1;
        padding:0 10px;
        &:last-child{
          padding: 0;
          flex: unset;
          width: 170px;
          //border:1px solid red;
          .btn{
            margin: 0;
            display: block;
            width: 100%;
          }
        }
        .form-control{
          padding-left: 10px;
          background: white;
        }
      }
    }
  }

  .count-days {
    padding: 40px 0 10px 0;
    text-align: center;

    h3 {
      text-transform: uppercase;
      color: white;
      font-size: 5rem;
    }

    h2 {
      color: white;
      font-size: 6rem;
      font-family: Arial, sans-serif;
      text-transform: uppercase;
    }

    p {
      padding-top: 20px;
      font-weight: 600;
      font-size: 1.4rem;
      color: white;
    }
  }
}