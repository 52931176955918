header {
  a, li {
    font-size: 1.3rem !important;
  }

  .top-bar {
    padding: 10px;
    background: white;
    border-bottom: 1px solid rgba(black, 0.1);

    a {
      font-size: 1.2rem;
      color: grey;

      &:hover {
        color: black;
      }
    }

    .module-cart {
      span.cart-label {
        background: $color_primary;
        padding: 0 6px;
        margin-left: 3px;
        color: white;
        line-height: 4px;
      }
    }
  }

  nav.nav-site {
    background: white;
    padding: 5px 0;

    .logo {
      height: 65px;
      display: flex;
      justify-content: left;
      align-items: center;

      @include mobile {
        flex-direction: column-reverse;
        height: auto;
        padding-bottom: 5px;
      }

      a {
        width: 100%;
        display: block;

        img {
          max-width: 200px;
          max-height: 75px;
        }

      }
    }

    ul.nav-menu {
      display: flex;
      //border: 1px solid grey;
      height: 60px;
      align-items: center;

      li {
        flex: 1;

        a {
          display: block;
          width: 100%;
          font-weight: 600;
          line-height: 40px;
          text-transform: uppercase;
          color: darken(grey, 20);
          //border: 1px solid green;
          text-align: center;
          position: relative;
          font-size: 1.2rem !important;
          letter-spacing: 1px;


          &:before {
            width: 70%;
            height: 2px;
            background: $color_primary;
            content: '';
            position: absolute;
            left: 50%;
            bottom: 2%;
            transition: all .3s ease;
            transform: translateX(-50%) scaleX(0);
          }

          &:hover {
            text-decoration: none;
            color: $color_primary;

            &:before {
              transform: translateX(-50%) scaleX(1);
            }
          }

          &.active {
            color: $color_primary;

            &:before {
              width: 70%;
              transform: translateX(-50%) scaleX(1);
            }
          }
        }
      }
    }

  }


  @include mobile {

    .nav-site {
      .logo {
        a {
          width: 100%;

          img {
            margin: 0 auto;
            //width: 100% !important;
          }
        }
      }
    }

    .menu-open {
      width: 100%;
      height: auto;
      text-align: center;
      text-transform: uppercase;
      display: block;
      //border: 1px solid red;
      line-height: 1;
      padding: 8px;
      color: black;
    }

    ul.nav-menu {
      position: fixed !important;
      height: 100% !important;
      z-index: 999;
      width: 100%;
      left: 0;
      top: -200%;
      background: white;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      padding: 20px;
      display: block !important;
      transition: all .3s ease;

      &.active {
        top: 0;
      }

      li {
        flex: unset;
        //border: 1px solid grey;
        width: 100%;
        height: auto !important;
        line-height: 1;

        &.logo-menu-mobile {
          display: block;
          padding: 10px;

          img {
            margin: 0 auto;
            width: 80%;
          }
        }
      }
    }
  }
}