.sidebar {

  ul {
    li {
      a {
        color: $color_primary;
        font-size: 1.3rem;
        font-weight: 600;
        transition: all .3s ease;

        &:hover {
          color: black;

          svg {
            g, path {
              fill: black;
            }
          }
        }
      }
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 1.7rem;
    color: grey;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 15px;
    margin-bottom: 5px;

    &:first-child {
      margin-top: 0;
    }
  }

  .subcategorias-list {

  }

  .categorias-list {
    .item {
      padding: 2px 0;

      a {
        transition: all .3s ease;

        &:hover {
          opacity: 1;


          figure {
            svg {
              a, path {
                fill: black;
              }
            }
          }
        }

        figure {
          height: 15px;
          display: inline-block;
          margin-right: 2px;


          svg {
            display: inline-block;
            height: 1.8rem;
            transform: translateY(4px);

            g, path {
              transition: all .3s ease;
              fill: $color_primary
            }
          }

        }
      }
    }
  }
}