section[data-section='detail-rep'] {
  //border:1px solid red;
  padding: 5px 0 20px 0;

  .close-detail {
    position: absolute;
    right: 1%;
    top: 1.8%;
    font-size: 2.5rem;
    line-height: 1;
    //border: 1px solid red;
    display: block;
    z-index: 999;
    color: grey;
    transition: all .3s ease;

    &:hover {
      color: $color_primary
    }
  }

  .rep-galeria {
    figure.default {
      position: relative;
      background: white;
      box-shadow: 0 0 2px grey;
      height: 450px;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 80%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .detail-rep-data {
    padding: 5px;

    p.observacion {
      font-size: 1.5rem;
      color: black;
      font-weight: 400;
      margin-bottom: 2px;
    }

    .precio-detail{
      margin-bottom: 10px;
      display: block;
    }

    .subtitle-section {
      margin-bottom: 0;
      margin-top: 0;
    }

    .title-section {
      color: black;
      margin-bottom: 1px;
      padding-bottom: 0;
      font-weight: 700;
    }
  }

  figure.gal {
    position: relative;
    background: white;
    //border: 1px solid green;
    height: 300px;
    display: block;
    width: auto;
    margin: 0 auto;

    img {
      width: auto !important;
    }

    .gal-repuesto {
      height: auto;
      position: absolute;
      top: 50%;
      width: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      //margin:0 auto;
      vert-align: middle;
      //border: 1px solid red;

      img {
        //display: block;
        //margin: 0 auto;
        //vert-align: middle;
        max-height: 300px;
      }
    }
  }

  .vehiculos-rep {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;

      li {
        flex-basis: 49%;
        font-size: 1.4rem;
        background: white;
        text-align: left;
        padding: 3px 3px 3px 0;
        margin: 0 0 3px 0;
        //box-shadow: 1px 1px 2px 1px rgba(grey, .5);
      }
    }
  }


  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 888;

    &.left-a, &.rigth-a {
      background: rgba(black, .5);
      padding: 15px 10px;
      color: white;
      transition: all .3s ease;
      border: none;

      &:hover {
        background: white;
        color: black;
      }
    }

    &.left-a {
      left: 0;
    }

    &.rigth-a {
      right: 0;
    }
  }
}


.detail-rep {
  display: none;
  position: fixed;
  width: 90%;
  height: auto;
  max-height: 90%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 0 2px grey;
  z-index: 1999;

  .title-section{
    margin-top: 0;
    font-size: 2.6rem;
    color:$color_primary !important;
  }

  .vehiculos-rep {
    //border: 1px solid red;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
  }
}

.detail-rep-back {
  display: none;
  z-index: 1900;
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(black, .5);
  position: fixed;
  left: 0;
  top: 0;
}
