section[data-section='0km-tienda'] {
  .result-cero {
    .sidebar-versions {
      display: inline-block;
      vertical-align: top;
      width: 20%;
      @include mobile {
        width: 100%;
      }

      ul {
        margin-top: 10px;
        padding-right: 10px;

        li {
          margin-bottom: 8px;

          a {
            text-align: center;
            width: 100%;
            display: block;
            color: $color_primary;
            font-weight: 700;
            border-radius: 2px;
            font-size: 1.2rem;
            transition: all .3s ease;
            border: 1px solid $color_primary;
            padding: 5px;
            background: white;

            &:hover {
              color: white;
              background: $color_primary;
            }
          }
        }
      }
    }

    .listado-cero {
      display: inline-block;
      width: 79%;

      @include mobile {
        width: 100%;
      }
    }
  }

  .load {
    //border:1px solid red;
    padding: 0 6px;
  }

  .cargando {
    text-align: center;

    h4 {
      text-transform: uppercase;
      display: block;
      margin: 5px 0 10px 0;
      color: $color_primary;
    }

    img {
      width: 15%;
      margin: 0 auto;
      display: block;
    }
  }

  ul.list-tipos-autos {
    display: flex;
    @include mobile {
      flex-direction: column;
    }

    li {
      flex: 1 1;

      a {
        color: $color_primary;
        padding: 10px 0;
        font-weight: 700;
        text-transform: uppercase;
        display: block;
        text-align: center;
        position: relative;
        //border:1px solid red;

        &:after {
          transition: all .3s ease;
          display: block;
          content: '';
          position: absolute;
          width: 90%;
          height: 2px;
          background: $color_primary;
          left: 50%;
          transform: translateX(-50%) scaleX(0);
          bottom: 0;
        }

        &:hover {
          &:after {
            transform: translateX(-50%) scaleX(1);
          }
        }
      }
    }
  }

  ul.list-models {

    display: flex;

    @include mobile {
      display: flex;
      //flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    li {
      flex: 1 1;
      padding: 5px;
      background: white;
      box-shadow: 0 0 2px grey;
      margin: 5px;
      border-radius: 3px;
      transition: all .3s ease;

      @include mobile {
        width: 48%;
        float: left;
        flex: unset;
        margin-left: 0;
        margin-right: 0;
      }

      &:hover {
        box-shadow: 0 0 12px grey;
      }

      figure {
        line-height: 0;
        height: auto;
        margin-bottom: 5px;

        img {
          max-height: 65px;
          margin: 0 auto;
          display: block;
          max-width: 100%;
        }
      }

      a {
        font-size: 1.3rem;
        font-weight: 600;
        color: $color_primary;
        display: block;
        text-align: center;
        text-transform: uppercase;
        height: auto;
      }
    }
  }
}