section[data-section='usados'] {

  #filter-usado {
    padding-bottom: 10px;

    input[type='text'] {
      padding: 25px 20px;
      font-size: 1.6rem;
      border-radius: 5px;
    }
  }

  .back-contacto {
    position: fixed;
    background: rgba(0, 0, 0, .4);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 900;
    cursor: pointer;
    display: none;
  }

  .consulta-usado {
    position: fixed;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 70vw;
    background: white;
    z-index: 1999;
    left: 50%;
    top: 50%;
    box-shadow: 0 0 2px grey;
    transform: translate(-50%, -50%);
    padding: 10px;
    display: none;
  }

  table {
    table-layout: fixed !important;

    @include mobile {
      table-layout: unset !important;
    }
  }

  .item-usado {
    //border:1px solid red;
    text-align: center;
    border-radius: 2px;
    margin-bottom: 15px;

    min-height: 370px;
    //max-height: 450px;
    height: 390px;
    background: white;
    box-shadow: 0 0 2px 1px rgba(128, 128, 128, 0.7);
    transition: all .3s ease-in-out;

    &:hover {
      box-shadow: 0 0 12px 2px rgba(128, 128, 128, 1);
    }

    figure {
      margin-bottom: 10px;
      text-align: center;
      width: 100%;
      height: 230px;
      overflow: hidden;
      line-height: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .text-destacado {
        color: red;
        font-size: 12px;
      }

      figcaption {
        //border: 1px solid red;
        position: absolute;
        background-color: red;
        left: -31.4%;
        overflow: hidden;
        top: 15%;
        display: block;
        width: 100%;
        height: auto;
        line-height: 2.5;
        transform: rotate(-40deg);

        span, p {
          text-align: center;
          display: block;
          margin: 0;
          padding: 0;
          color: white;
        }
      }


      img {
        margin: 0 auto;
        display: block;
      }


      .no-image {
        padding: 10px;
        display: block;
      }

      @include mobile {
        height: 180px;
        padding: 5px 0;
      }
    }

    .title-usado {
      text-transform: uppercase;
      text-align: center;
      color: $color_primary;
      font-size: 1.3rem;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .precio-usado {
      font-weight: 600;
      font-size: 2.6rem;
      margin-bottom: 1px;
    }

    .btn-select-usado {
      background: transparent;
      color: $color_primary;
      text-transform: uppercase;
      font-size: 1.2rem;
      transition: all .3s;

      @include mobile {
        width: 100%;
        padding: 10px;
        font-size: 1.5rem;
        margin: 10px 0 5px 0;
        clear: both;
        display: block;
      }

      &:hover {
        background: $color_primary;
        color: white;
      }
    }
  }

  .slider-container {
    position: relative;

    .images-slider {
      img {
        display: block;
        margin: 0 auto;
      }
    }

    button.left, button.right {
      background: rgba(black, 1);
      position: absolute;
      //border:none;
      color: white;
      padding: 15px 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 777;
      transition: all .3s;
      border: 1px solid black;

      i {
        transition: transform .3s;
      }

      &:hover {
        border-color: black;
        background: white;
        color: black;

      }
    }

    button.left {
      left: 0;

      &:hover {
        i {
          transform: translateX(-2px);
        }
      }
    }

    button.right {
      right: 0;

      &:hover {
        i {
          transform: translateX(2px);
        }
      }
    }
  }
}