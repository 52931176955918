.info-content {
  h3, h4 {
    margin: 0 !important;
  }
}

footer {
  padding: 0;
  background-color: #191919;

  .logos-footer {
    @include mobile {
      > div {
        text-align: center;
        padding: 5px 0;

        img {
          max-height: 50px;
          display: block !important;
          text-align: center;
          margin: 0 auto;
        }
      }
    }
  }
}

.form-newsletter {
  padding-bottom: 25px;

  .subtitle-section {
    margin-bottom: 5px;
  }

  form {
    border: 1px solid rgba(black, .1);
    padding: 20px;
    display: flex;
    justify-content: space-between;

    fieldset {
      line-height: 0;
    }

    .form-control {
      margin-bottom: 0 !important;
    }

    > div {
      margin: 0 12px 0 0;
      flex: 1;
      //border: 1px solid red;

      input, .btn {
        height: 38px;
      }

      input {
        border: none;
        border-bottom: 1px solid rgba(black, .2);
      }
    }

  }
}

.footer-info {
  .row {
    padding-top: 100px;
    padding-bottom: 60px;
    border-bottom: 1px solid #232323;
  }

  .panel-info {
    border-radius: 1px;
    border: 1px solid rgba(229, 229, 229, 0.1);
    padding: 21px;
    position: relative;
  }

  .info-icon {
    position: absolute;
    top: -20px;
    right: 21px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 2px;
    background-color: #d22e2e;
    color: #000000;
    font-size: 32px;
  }

  .info-content {
    h4 {
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    p {
      color: #999999;
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 0;
    }
  }
}

.footer-widget {
  padding: 70px 0;

  .footer-widget-title h5 {
    color: #ffffff;
    font-size: 17px;
    font-weight: 700;
    line-height: 1;
  }

  .footer-widget-content p {
    color: #999999;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }
}

.footer-social a {
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background-color: #141414;
  color: #acacac;
  font-size: 15px;
  font-weight: 400;
  display: inline-block;

  &:hover {
    color: #ffffff;
  }

  &.share-facebook:hover {
    background-color: #3B5998;
  }

  &.share-twitter:hover {
    background-color: #00ACEE;
  }

  &.share-pinterest:hover {
    background-color: #C8232C;
  }

  &.share-google-plus:hover {
    background-color: #DD4B39;
  }

  &.share-vimeo:hover {
    background-color: #86C9EF;
  }

  &.share-rss:hover {
    background-color: #EE802F;
  }
}

.footer-widget-link li {
  border-bottom: 1px solid #242424;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  a {
    color: #acacac;
    font-size: 13px;
    font-weight: 400;
    line-height: 36px;
    text-transform: uppercase;
    position: relative;
    padding-left: 15px;

    &:before {
      position: absolute;
      left: 0;
      content: "\f101";
      color: $color_primary_2;
      font-family: fontawesome, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 36px;
    }

    &:hover {
      color: $color_primary_2;
    }
  }
}

.footer-bar {
  padding: 30px 0;
  background-color: #141414;
}

.powered {
  p {
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    margin-bottom: 5px;
  }

  ul li {
    position: relative;
    line-height: 1;

    &:after {
      position: absolute;
      content: "-";
      right: -3px;
      top: 0;
    }

    &:last-child::after {
      display: none;
    }

    a {
      color: #acacac;
      font-size: 13px;
      font-weight: 400;
      line-height: 1;
      text-transform: capitalize;

      &:hover {
        color: #d22e2e;
      }
    }
  }
}

.payment-methods {
  line-height: 42px;
}

.footer hr {
  border-top-color: #232323;
  margin: 0;
}

/* Footer #2 */

.footer-2 .footer-info .info-icon {
  background-color: #191919;
  color: $color_primary_2;
}

/* Footer #4 */
.footer-4 .footer-bar {
  background-color: transparent;
}

/* Footer #6 */

.footer-6 .footer-info .row {
  border-bottom: none;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer-info .panel-info, .footer-widget-content {
    margin-bottom: 30px;
  }
  .footer-bar, .payment-methods {
    text-align: center;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-info .panel-info, .footer-widget-content {
    margin-bottom: 30px;
  }
}