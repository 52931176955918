.product {
  .product-img {
    position: relative;
    overflow: hidden;

    img {
      display: block;
      position: relative;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear;
      width: 100%;
      height: auto;
    }
  }

  .product-price {
    color: #d22e2e;
    font-weight: 700;
    font-size: 18px;
    line-height: 1;
    text-align: center;
  }

  .product-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.8);
    webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    .product-action {
      width: 100%;
      position: absolute;
      bottom: 30px;
    }

    a.btn {
      width: 95px;
      border-radius: 2px;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      margin-right: 20px;
      text-transform: capitalize;

      &:last-of-type {
        margin-right: 0;
      }

      &.btn-primary {
        transform: translateY(100px);
        -webkit-transform: translateY(100px);
        -moz-transform: translateY(100px);
        -o-transform: translateY(100px);
        -ms-transform: translateY(100px);
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }

      &.btn-secondary {
        float: right;
        transform: translateY(100px);
        -webkit-transform: translateY(100px);
        -moz-transform: translateY(100px);
        -o-transform: translateY(100px);
        -ms-transform: translateY(100px);
        -webkit-transition: all 0.2s 0.1s ease-in-out;
        -moz-transition: all 0.2s 0.1s ease-in-out;
        -o-transition: all 0.2s 0.1s ease-in-out;
        transition: all 0.2s 0.1s ease-in-out;
      }
    }
  }

  .product-img:hover {
    //img {
    //  transform: scale(1.1);
    //  -webkit-transform: scale(1.1);
    //  -moz-transform: scale(1.1);
    //  -o-transform: scale(1.1);
    //  -ms-transform: scale(1.1);
    //}

    .product-hover {
      opacity: 1;
    }

    a.btn.btn-primary {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0);
      -ms-transform: translateY(0);
    }
  }

  .product-bio {
    background-color: #ffffff;
    padding: 30px;

    .prodcut-cat a {
      color: #666666;
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      position: relative;
      text-transform: capitalize;
      display: block;
      margin-bottom: 12px;

      &:hover {
        color: #d22e2e;
      }
    }

    .prodcut-title h3 {
      margin-bottom: 13px;
      line-height: 1;

      a {
        color: #373636;
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 22px;

        &:hover {
          color: #d22e2e;
        }
      }
    }
  }
}

/* Product Hover Effect */

.product-num {
  margin-bottom: 70px;

  h3 {
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 0;
  }
}

.product-options {
  margin-bottom: 30px;
  position: relative;

  select {
    width: 230px;
    height: 35px;
    border: 1px solid #e5e5e5;
    color: #999999;
    font-size: 13px;
    font-weight: 400;
    padding-left: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  i {
    position: absolute;
    font-size: 13px;
    right: 10px;
    top: 10px;
    z-index: 1;
    pointer-events: none;
    cursor: pointer;
    color: #999999;
  }
}