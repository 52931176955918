section[data-section="servicios-auto"],
section[data-section="reparaciones-frecuentes"] {


  .item-reparacion, .item-servicio {
    &.card{
      width: 100% !important;
    }
    @include mobile {
      h3 {
        display: block;
        text-align: center;
      }
    }

    .detail {
      border-bottom: 1px solid rgba(black, .2);
      padding: 5px 0;
      margin-bottom: 5px;

      @include mobile {
        text-align: center;
        .btn {
          width: 100%;
        }
      }

      h4, h5 {
        line-height: 37px;
        font-size: 1.5rem;
        text-transform: uppercase;

        @include mobile {
          text-align: center;
          display: block;
          line-height: 25px;
        }
      }
    }
  }
}