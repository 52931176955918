section[data-section="repuestos"] {

  .repuestos-init {
    .banner {
      img {
        margin: 0 auto 10px auto;
        display: block;
      }
    }
  }

  .cat-principal {
    margin-top: 10px;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include mobile {
        flex-direction: column;
      }

      li {
        width: 16.1%;

        @include mobile {
          width: 100%;
        }

        margin-bottom: 10px;
        background: white;
        border: 1px solid rgba(128, 128, 128, 0.3);

        a {
          display: block;
          padding: 15px 10px 10px 10px;
          transition: all .3s ease;
          min-height: 160px;
          box-shadow: 0 0 0 0 grey;

          h3 {
            text-transform: uppercase;
            font-size: 1.4rem;
            margin-top: 2px;
            font-weight: 600;
            text-align: center;
            opacity: 0.8;
          }

          figure {
            height: 100px;

            img {
              text-align: center;
              display: block;
              margin: 0 auto;
            }

            svg {
              max-height: 85px;
              opacity: 0.7;
              transition: all .3s ease;

              g, path {
                fill: $color_primary;
              }
            }
          }

          &:hover {
            box-shadow: 0 0 6px 0 grey;

            svg {
              opacity: 1;
            }


            h3 {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.paginator {
  text-align: center;
  padding-bottom: 10px;
  margin-top: 15px;

  ul {
    display: inline-block;
    margin: 0 auto;

    button {
      background: white;
      color: $color_primary;
      margin: 0;
      padding: 5px 10px;
      font-weight: 800;

      &:hover {
        background: $color_primary;
        color: white;
      }

      &.active {
        background: $color_primary;
        color: white;
      }
    }
  }
}
