//@import url('https://fonts.googleapis.com/css?family=Heebo');
//@import url('https://fonts.googleapis.com/css?family=Encode+Sans+Semi+Expanded:100,200,400,600,800,900');
//@import url('https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700');
//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Overpass:wght@100;200;300;400;600;700;800&display=swap');

$color_primary: #0061a3;
$color_primary_2: #1bdafe;
$color_primary_3: #15A0FF;

//$FontWeb:'Heebo', sans-serif;
//$FontWeb:'Encode Sans Semi Expanded', sans-serif;
$FontWeb: 'Overpass', sans-serif;