section[data-section="cuenta"] {
  table {
    font-size: 1.4rem;
  }


  .item-mensaje {
    h3 {
      margin-bottom: 5px !important;
      color: $color_primary;
      font-weight: 700;
    }

    h5 {
      font-weight: 600;
      text-transform: uppercase;
    }

    p {
      padding-top: 15px;
      font-weight: 400;
      font-size: 1.5rem;
    }
  }
}