
section[data-section='planes-tienda'] {

  .item-plan {
    background: white;
    padding: 15px;
    box-shadow: 0 0 10px 2px lightgrey;
    min-height: 265px;
    margin-bottom: 15px;
    text-align: left !important;
    position: relative;

    figure {
      //border:1px solid red;
      height: 190px;
      overflow: hidden;
      position: relative;
      margin-bottom: 2px;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: 140px;
      }
    }

    .botones {
      //position: absolute;
      width: 100%;
      //right: 0;
      //bottom: 2%;
      display: flex;
      padding: 5px 10px 0 10px;

      @include mobile {
        position: relative;
        flex-direction: column;
        padding: 10px 0 0 0;
      }

      .btn {
        margin: 3px;
      }
    }

    .data {
      text-align: left !important;
      //position: relative;
      //border:1px solid red;

      h3 {
        font-weight: 700;
        color: $color_primary;
        text-transform: uppercase;
        text-align: left;
        margin-bottom: 5px;
      }

      h5, h4 {
        text-align: left;
      }

      h4 {
        margin-bottom: 5px;
        color: black;
        margin-top: 0;
      }

      .beneficios {
        margin-top: 10px;
        line-height: 1.1 !important;
        text-align: left !important;
      }
    }

    h4, h3, h5 {
      display: block;
      text-align: center;
      margin: 0;
    }

    h4 {
      font-size: 1.8rem;
      font-weight: 700;
      text-transform: uppercase;
      padding-bottom: 5px;
      color: $color_primary;
    }

    .precio {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 2.3rem;
    }
  }

  .modal-dialog {
    h3 {
      margin-top: 5px;
      text-transform: uppercase !important;
      font-family: "Ford Antenna", sans-serif;
      margin-bottom: 5px;
      color: $color_primary;
    }

    p, li, strong, b {
      font-family: sans-serif !important;
      color: black;
      font-weight: 400 !important;

    }
  }
}

section[data-section='contratar-plan'] {
  h3.subtitle-section {
    margin-bottom: 5px;
  }

  form {
    .form-control {
      margin-bottom: 5px;
    }
  }
}

.gradient {
  //background: -moz-linear-gradient(0% 50% 0deg, rgba(27, 218, 254, 1) 0%, rgba(78, 173, 254, 1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(27, 218, 254, 1) 0%, rgba(78, 173, 254, 1) 100%);
  background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0, rgba(27, 218, 254, 1)), color-stop(1, rgba(78, 173, 254, 1)));
  background: -o-linear-gradient(0deg, rgba(27, 218, 254, 1) 0%, rgba(78, 173, 254, 1) 100%);
  background: -ms-linear-gradient(0deg, rgba(27, 218, 254, 1) 0%, rgba(78, 173, 254, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#1BDAFE', endColorstr='#4EADFE' ,GradientType=0)";
  background: linear-gradient(90deg, rgba(27, 218, 254, 1) 0%, rgba(78, 173, 254, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1BDAFE', endColorstr='#4EADFE', GradientType=1);
}

.pasos-planes {
  text-align: center;
  padding-top: 0;
  padding-bottom: 10px;
  display: block;

  h2 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 3.9rem;
    color: black;
  }

  p {
    color: black;
    font-size: 1.4rem;
    line-height: 0.9;
    margin-bottom: 0;
  }
}

.pasos {
  padding-top: 10px;
  margin-bottom: 10px;
  display: block;
  clear: both;
  height: auto;

  > div {
    padding: 0 3px 0 3px;
  }

  figure {
    padding: 5px 0 0 0;
    text-align: center;

    svg {
      height: 70px;

      path {
        fill: $color_primary_2;
      }
    }

    img {
      max-height: 80px;
    }

    figcaption {
      padding: 5px 0;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.4rem;
      text-align: center;
    }
  }

  .item-paso {
    height: 40px;
    position: relative;
    width: 100%;
    display: block;
    margin-bottom: 10px;

    &:before {
      content: '';
      width: 100%;
      position: absolute;
      height: 100%;
      z-index: 8;
      @extend .gradient;
      transform: skewX(10deg);
    }

    &.active {
      &:before {
        background: black;
        color: $color_primary;
      }
    }


    h3 {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      z-index: 800;
      display: block;
      text-align: center;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      color: white;
      font-size: 1.4rem;
      font-weight: 800;
    }
  }
}