body, html {
  font-size: 10px;
  overflow-x: hidden;

  p, li, button, a, label, select, input, textarea {
    font-family: $FontWeb, sans-serif !important;
  }

  h1, h2, h3, h4, h5, figcaption {
    font-family: 'FordAntenna-Medium', sans-serif;
  }

  #counter-container {
    width: 90%;

    @include mobile {
      padding-top: 5px;
      padding-bottom: 15px;
    }

    .apurate {
      //background-color: red;
      color: white;
      padding: 0;
      text-align: center;
      overflow-x: visible;
      display: flex;
      font-size: 1.2rem;
      font-weight: 700;
      align-items: center;

      img {
        height: 20px;
      }

      span {
        line-height: 2rem;
        background: $color_primary;
        flex-basis: 95%;
      }
    }

    #counter-promo {
      ul {
        display: flex;

        li {
          flex: 1;
          text-align: center;
          //border: 1px solid red;
          font-size: 2.5rem !important;
          font-weight: 600;
          color: $color_primary;

          span {
            text-transform: uppercase;
            color: black;
            line-height: 0;
            font-weight: 400;
            font-size: .9rem;
            display: block;
          }
        }
      }
    }
  }

  .old {
    text-decoration: line-through;
    color: rgba(black, .5);
    opacity: 0.8;
  }


  .affix {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    box-shadow: 0 0 2px black;
  }

  fieldset {
    margin: 0 !important;
    padding: 0;
  }

  ul, li {
    margin: 0;
    padding: 0;
  }

  p {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 1px;
  }

  a {
    color: $color_primary;
    transition: all .3s ease;

    &:hover {
      text-decoration: none;
    }
  }


  label {
    font-size: 1.3rem;
  }

  .form-control {
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    border-color: #cecece;
    //border-top: none;
    //border-left: none;
    //border-right: none;
    //border-bottom: 1px solid rgba($color_primary, .3);
    transition: all .3s ease;

    &:focus, &:active {
      box-shadow: none;
      border-bottom: 1px solid $color_primary;
    }
  }

  form {
    .form-control {
      margin-bottom: 5px;
    }
  }

  .item-suc {
    border-bottom: 1px solid grey;
    padding-top: 10px;
    padding-bottom: 10px;

    h4, h5, h6 {
      margin: 0;
    }
  }

  .card {
    padding: 15px 20px;
    box-shadow: 0 0 1px grey;
    margin-top: 10px;
    margin-bottom: 10px;

    label {
      color: black;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    .title-card, h4, h3, h2, h1 {
      margin-bottom: 10px;
      margin-top: 0;
      padding-top: 0;
    }

    input {
      margin-bottom: 5px;
    }

    form {
      margin-bottom: 0;
    }

    a {
      margin: 0;
    }
  }


  .subheading {
    margin-top: 10px;
    margin-left: 3px;
    margin-bottom: 6px !important;
    color: $color_primary !important;

    &:before {
      background: $color_primary !important;
    }
  }


  .flash-messages {
    //position: fixed;
    //left: 50%;
    //transform: translateX(-50%);
    //
    //bottom: -100%;
    width: 80%;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    z-index: 888;
  }

  .cart-total {
    margin-top: 0;

  }

  .btn-primary {
    background: $color_primary;
    border-color: $color_primary;
    border-radius: 0;
    height: auto;
    line-height: 1;
    background: $color_primary;
    transition: all .3s ease;

    &:hover {
      background: darken($color_primary, 10);
    }

    &:focus {
      &:before {
        background-color: darken($color_primary, 12);
      }
    }

    &:hover {
      border-color: darken($color_primary, 5);


      &:before {
        background: darken($color_primary, 10);
      }

    }
  }

  ul, li {
    padding: 0;
    font-size: 1.6rem;
    color: black;
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
  }

  li, ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .modal-dialog {
    width: 88%;

    p {
      text-align: left;
      color: grey;
      font-size: 1.4rem;
      line-height: 1.2;
      text-transform: unset;
    }

    table {
      color: black;
    }
  }

  table {
    font-size: 1.4rem;
  }


  .aviso {
    position: fixed;
    left: 50%;

    top: 50%;
    height: 100vh;
    width: 100vw;
    transform: translate(-50%, -50%);
    z-index: 2100;
    display: none;
    background: rgba(black, .5);

    .body {
      text-align: center;
      width: 40%;
      height: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      background: white;
      box-shadow: 0 0 2px grey;
      padding: 20px;

      h3, h4 {
        text-transform: uppercase;
        padding: 10px 0;
      }

      img {
        max-width: 50%;
        display: block;
        margin: 0 auto;
        padding: 5px;
      }
    }

  }

}

section {
  padding: 25px 0 50px 0;
  display: block;

  &.no-padding {
    padding-top: 0 !important;
  }
}

.title-section {
  margin-top: 10px;
  margin-bottom: 15px;
  text-transform: uppercase !important;
  letter-spacing: 2px;
  font-size: 3.9rem;
  font-weight: 500;
  color: $color_primary;

  @include mobile {
    text-align: center;
    display: block;
  }
}

.subtitle-section {
  margin-top: 15px;
  margin-bottom: 25px;
  display: block;
  text-transform: uppercase !important;
  letter-spacing: 2px;
  font-size: 2.2rem;
  font-weight: 700;
  color: $color_primary;
}


.item-cat {
  padding: 13px 10px 10px;
  background: white;
  box-shadow: 0 0 2px grey;
  margin-bottom: 10px;
  border-radius: 3px;
  height: 210px;
  position: relative;

  .btn {
    position: absolute;
    bottom: 7px;
    left: 50%;
    width: 95%;
    transform: translateX(-50%);
    background: $color_primary;
    border-color: $color_primary;
    transition: all .3s ease;


  }

  figure {
    line-height: 0;
    position: relative;
    text-align: center;
    margin-bottom: 5px;
    display: block;
    //border: 1px solid red;

    svg {
      height: 100px;

      g, path {
        fill: black;
      }
    }

    img {
      height: 110px;
      margin: 0 auto;
      //max-width: 100%;
    }

    figcaption {
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      padding: 5px 0;
      //background: rgba(black, .4);
      margin-top: 10px;

      h3, h4 {
        color: $color_primary;
        //text-shadow: 0 0 2px black;
        margin: 0;
        padding: 0;
        font-size: 1.8rem;
      }
    }
  }

  h3 {
    text-transform: uppercase;
    text-align: center;
    display: block;
    font-size: 1.9rem;
    margin-bottom: 10px;
  }

  .btn {
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 1.2rem;
  }

  .buttons {
    width: 100%;
    //border:1px solid red;
    display: flex;
    margin-top: 5px;

    a {
      color: $color_primary;
      padding: 7px 0;
      font-weight: 700;
      margin: 4px;
      text-transform: uppercase;
      flex: 1 1;
      text-align: center;
      border: 1px solid $color_primary;

      &:hover {
        background: $color_primary;
        color: white;
      }
    }
  }
}


ul.medios {
  display: flex;
  margin: 0 0 10px 0;

  li {
    margin: 0 5px 0 0;
    flex: 1 1;
    //border: 1px solid red;
    text-align: center;
    position: relative;
    opacity: .5;

    &.active {
      box-shadow: 0 0 18px $color_primary;
      opacity: 1;
    }

    a {
      border-radius: 5px;
      height: 120px;
      background: white;
      box-shadow: 0 0 2px grey;
      display: block;
      width: 100%;
      transition: all .3s ease;
      //border: 3px solid red;
      &:hover {
        box-shadow: 0 0 6px grey;
      }
    }

    figure {
      padding: 10px 20px 0 20px;
      position: relative;
      //border:1px solid red;
      height: 80%;

      img {
        width: auto;
        height: 70%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 80px;
      }
    }

    h3 {
      position: absolute;
      bottom: 7%;
      display: block;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-size: 1.7rem;
    }
  }
}

.title-h4 {
  text-transform: uppercase;
  margin-bottom: 5px;
  color: $color_primary;
  font-weight: 400;
}

.btn-mercadopago {
  margin: 5px 0 !important;
  display: block !important;
}

.price-old {
  color: grey;
  text-decoration: line-through;
}

a[data-title],
button[data-title] {
  position: relative;
}

a[data-title]:hover:after,
button[data-title]:hover:after {
  content: attr(data-title);
  padding: 4px 8px;
  color: white;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 20;
  white-space: nowrap;
  border-radius: 2px;
  box-shadow: 0 0 4px grey;
  background: $color_primary;
}


.modal-body {

  .image-car {
    text-align: center;
    max-height: 180px;
  }

  form {
    .form-group {
      margin-bottom: 5px;
    }

    label {
      color: $color_primary;
      text-transform: uppercase;
    }

    .form-control {
      margin-bottom: 0;
      border: 1px solid rgba(grey, 0.4);
    }
  }
}

