section[data-section='pagar'] {
  h2 {
    text-transform: uppercase;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: $color_primary;
    margin-bottom: 10px;
  }

  p.detail-link {
    padding: 10px 0;
    font-size: 1.4rem;
    font-weight: 400;
  }
}