.item-repuesto {
  background: white;
  border-radius: 3px;
  box-shadow: 1px 1px 4px #b3b3b3;
  margin-bottom: 10px;
  height: 550px;
  position: relative;

  .res-consulta{
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 13px;
  }

  .carousel {
    position: unset;

    .carousel-inner {
      position: unset;

      .item {
        height: 330px;

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%) !important;
        }
      }
    }

    a[data-slide="prev"], a[data-slide="next"] {
      background: transparent;

      span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $color_primary;
        text-shadow: none;

        i {
        }
      }
    }

    .carousel-indicators {
      bottom: 0;

      li {
        background: white;
        box-shadow: 0 0 3px rgba(black, .7);
      }
    }
  }

  .show-asocs {
    display: block;
    text-transform: uppercase;
    width: 85%;
    background: transparent;
    color: $color_primary;
    border: 1px solid $color_primary;
    margin: 8px auto 0 auto;
    padding: 6px;
    transition: all .3s ease;

    &:hover {
      color: white;
      background: $color_primary;
    }
  }

  .botones-add {
    width: 85%;
    margin: 0 auto;

    > div {
      padding: 3px;

      &:nth-child(1) {
        padding-left: 0;
      }

      &:nth-child(2) {
        padding-right: 0;
      }
    }

  }

  .asocs {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(black, .5);
    width: 100%;
    height: 100%;
    z-index: 998;

    .close-asocs {
      position: absolute;
      left: 50%;
      background: white;
      color: black;
      bottom: 3%;
      transform: translateX(-50%);
      width: 90%;
      padding: 5px 0;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      transition: all .3s ease;
      border: 1px solid white;

      &:hover {
        background: $color_primary;
        color: white;
        border: 1px solid white;
      }
    }

    h3 {
      padding: 5px 0;
      text-transform: uppercase;
      color: white;
      background: $color_primary;
      font-weight: 800;
      letter-spacing: 2px;
      font-size: 1.8rem;
      width: 100%;
      text-align: center;
      margin: 0 auto 3px;
    }

    .asoc-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: white;
      box-shadow: 0 0 2px grey;
      text-align: center;
      width: 90%;
      margin: 0 auto;
      max-height: 70%;
      overflow-y: auto;

      ul {
        padding: 3px 15px 15px 15px;

        li {
          border-bottom: 1px solid rgba($color_primary, 0.5);
          margin-bottom: 7px;

          &:last-child {
            margin-bottom: 0;
          }

          h5 {
            margin: 0;
            font-size: 1.3rem;
            color: $color_primary !important;
          }

          h6 {
            font-size: 1.2rem;
            margin: 2px 0 0 0;
          }
        }
      }
    }

  }

  .product-hover {
    background: rgba($color_primary, 0.2);

    &:hover {
      //opacity: 0.5 !important;
      //background: rgba(black, .2) !important;
    }
  }

  .code-rep {
    display: block;
    text-align: center;
    font-size: 1.3rem;
    color: $color_primary;
    margin: 5px 0;
    font-weight: 400;
    text-transform: uppercase;
  }

  .product-img {
    height: 330px;
    position: relative;
    overflow: hidden;

    .logo-default {
      position: absolute;
      left: 50%;
      top: 50%;
      padding: 8%;
      transform: translate(-50%, -50%);

      &.image-1{
        padding: 0;

      }
    }
  }


  .product-action {
    text-align: center;

    a.btn {
      margin: 0 5px;
    }
  }

  .product-bio {

    .product-title {
      a {
        h3 {
          color: black;
          display: block;
          text-align: center;
          text-transform: uppercase;
          font-size: 1.5rem;
          margin-bottom: 3px;
        }
      }
    }

    .product-price {
      text-transform: uppercase;
      color: $color_primary
    }
  }

}