section[data-section="datos-cero"] {
  #form-compra-cero {
    label {
      margin-bottom: 0;
      font-size: 14px;
      font-family: $FontWeb;
      font-weight: 600;
    }

    input, textarea, select {
      border: 1px solid $color_primary_3;
      border-radius: 8px;
      margin-bottom: 10px;
    }

    .btn {
      line-height: 1;
      font-family: $FontWeb;
      margin: 15px auto 0 auto;
      display: block;
      padding: 12px 15px !important;
      border-radius: 5px;
      text-transform: uppercase;
    }
  }

  .title {
    color: $color_primary_3;
    margin-bottom: 5px;
  }

  ul.datos-financiacion {
    li {
      width: 80%;
      display: flex;
      justify-content: space-between;

      b {
        font-family: FordAntenna-Medium, sans-serif;
      }

      span {
        font-family: FordAntenna-Light, sans-serif;
      }

    }
  }
}