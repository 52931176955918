section[data-section="contratar-plan"] {

  .data-plan {
    position: relative;

    h3 {
      position: absolute;
      right: 2%;
      padding: 10px;
      background: $color_primary;
      margin: 0;
      top: 0;
      line-height: 1;
      color: white;
      font-weight: 800;
      text-transform: uppercase;
    }

    h4 {
      color: $color_primary;
      font-weight: 700;
      margin-top: 10px;
    }

    p {
      margin-top: 5px;
      font-size: 1.4rem;
      font-weight: 400;
    }
  }

  form {
    .btn {
      letter-spacing: 1px;
      font-weight: 600;
      padding: 10px 20px;
      border-radius: 8px;
    }
  }
}