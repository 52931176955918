section[data-section="financiacion"] {

  .title-section, h3, h4, h5 {
    font-weight: 600;
    margin-bottom: 0;
    font-family: "Ford Antenna", sans-serif;
  }

}

.tasas {
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .item-financiacion {
    border: 1px solid white;
    flex: 1 1;
    margin: 10px 5px;
    transition: all .3s;
    border-radius: 10px;

    header {
      padding-bottom: 0 !important;

      p {
        font-size: 12px;
      }
    }

    &.active {
      background: white;
      box-shadow: 0 0 2px grey;
      //border-color: rgba($color_primary, .5)
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    //&:hover {
    //  box-shadow: 0 0 4px 1px grey;
    //}

    label {
      margin-bottom: 0;
      font-size: 1.1rem;
      text-align: center;
      display: block;
    }

    header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0;
      flex-direction: column;

      h3 {
        text-transform: uppercase;
        margin: 0;
        color: $color_primary;
        font-weight: 700;
      }

      span {
        color: $color_primary;
      }
    }

    .item-body {
      padding: 5px 10px;
    }

    .boton-fin {
      padding: 10px 10px 5px 10px;
    }

    .item-row {
      display: flex;
      border-bottom: 1px solid rgba(black, .2);
      align-items: center;
      padding: 8px 0;

      > span {
        flex: 1 1;
        text-align: center;
        //padding: 10px 0;
        font-size: 1.2rem;
        color: black;

        &.title {
          //padding: 5px 0;
          margin-bottom: 0;
          text-transform: uppercase;
          font-weight: 700;
          padding: 0;
        }

        &.text {
          input {
            text-align: center !important;
          }
        }
      }
    }
  }

  label[for='ingresar'] {
    margin-bottom: 5px;
  }

  //small{
  //  margin-bottom: 10px;
  //  display: block;
  //}
}
