section[data-section="mant"] {
  text-align: center;

  h2, p {
    text-align: center !important;
    display: block;
  }

  .title-section {
    text-align: center !important;
  }

  figure {
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
      max-height: 100px;
    }
  }
}