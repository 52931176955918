.item-cero {
  background: white;
  box-shadow: 0 0 2px grey;
  padding: 10px;
  min-height: 280px;
  margin-bottom: 10px;
  margin-top: 10px;

  span.color-name {
    text-transform: uppercase;
    text-align: center;
    display: block;
    font-size: 1.4rem;
    padding: 2px 0;
    min-height: 25px;
    text-shadow: 1px 1px 1px black;
    font-weight: 800;
  }

  .fichas {
    padding-top: 5px;

    a {
      font-size: 1rem;
      display: block;
      //text-align: center;
      color: $color_primary;
      //padding: 6px 0 0 0;
      text-transform: uppercase;
      font-weight: 600;
      margin-top: 0;
    }
  }

  figure {
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .data {
    text-align: center;

    h3 {
      margin: 0 0 3px 0;
      padding: 0;
      line-height: 1.2;
      font-size: 1.9rem;
      height: 43px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .btn-primary {
    background: transparent;
    color: $color_primary;
    font-weight: 700;
    font-size: 1.3rem;
    border-width: 2px;
    border-radius: 5px;

    &:hover {
      color: white;
    }
  }

  ul.colores-list {

    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    width: 70%;
    margin: 10px auto 1px auto;

    li {
      flex: 1 1;
      text-align: center;
      align-content: center;
      display: flex;
      justify-content: space-around;

      a {
        border-radius: 20px;
        box-shadow: 0 0 1px 1px grey;
        display: block;
        height: 25px;
        width: 25px;

        &.change-color {
          &.active {
            position: relative;

            &:before {
              content: '\f00c';
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              color: white;
              text-shadow: 0 0 3px black;
              font-family: FontAwesome, sans-serif;
            }
          }
        }

      }
    }
  }
}

.item-cero-selected {
  padding: 40px 20px;
  box-shadow: 0 0 2px grey;

  h2 {
    color: $color_primary;
    margin-bottom: 2px;
  }


  ul.data {
    li {
      font-size: 1.3rem;
    }

    input[type='radio'] {
      transform: translateY(2px);
      background: white;
      //-webkit-appearance:inherit;
    }
  }

  .btn-confirm {
    margin-top: 5px;
    background: transparent;
    color: $color_primary;
    border-color: $color_primary;
    border-radius: 5px;
    font-weight: 500;

    &:hover {
      color: white;
    }
  }

}