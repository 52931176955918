section[data-section='carrito'] {
  .title-cart-item {
    margin-bottom: 10px;
    text-align: center;
    font-size: 1.5rem;
    padding-top:10px;
    display: block;
  }
  .type{
    display: block;
    color:$color_primary;
    text-align: center;
    text-transform: uppercase;
    padding-top: 5px;
  }
  .card {
    padding: 30px 10px;
    box-shadow: 0 0 1px grey;
    margin-bottom: 5px;

    h3 {
      margin: 0;
      text-transform: uppercase;
    }

    ul.cant {
      display: flex;
      //border:1px solid red;
      height: 100%;
      padding: 0;

      li {
        flex: 1 1;
        margin: 0;
        //border: 1px solid grey;
        height: 35px;
        display: flex;
        font-size: 1.3rem;
        text-transform: uppercase;
        justify-content: center;
        padding-right: 3px;
        align-items: center;

        &:last-child {
          flex: unset;
          width: auto;
          padding: 0;
        }

        input[type='number'] {
          display: inline-block;
          width: 48%;
          text-align: center;
          margin-left: 5px;
          height: 25px;
          transform: translateY(2px);
          color: black;
          padding: 1px 0 1px 8px;
          border: 1px solid rgba(grey, .5);
        }
      }
    }
  }
}