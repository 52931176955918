#busqueda-rep {
  div[class^='col'] {
    //border: 1px solid red;
    //height: 40px;
    padding-right: 2px;

    &:last-child {
      padding-right: 15px;
    }
  }


  .form-control {
    height: 40px;
  }

  label {
    margin-bottom: 0;
    height: auto !important;
    text-transform: uppercase;
  }

  .codigo {
    .row > div {
      padding-right: 3px;
      padding-left: 3px;
    }

    .form-control {
      //padding-left: 0;
      text-align: center;
    }
  }

  .btn {
    color: white;
    height: 38px;
    width: 100%;
    border-radius: 0;
    transform: translateY(18px);
  }
}